
  
  .inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  
  
  .popupCard {
    min-width: 360px;
    padding: 2rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    border-radius: 4px;
    background: white;
  }
  
  .popupCard > form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .popupCard > form > p {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  .btn-row {
    display: flex;
    flex-direction: row;
  }
  
  .btn-modal {
    border: none;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin: 1rem;
  }
  
  .btn:disabled {
    background-color: rgba(0, 0, 0, 0.4);
    cursor: not-allowed;
  }
  
  .margin-top--small {
    margin-top: 1rem;
    margin-bottom: -1rem;
  }
  
  .margin-top--small > div {
    flex-wrap: wrap;
  }
  
  .margin-top--small > div > div {
    margin-bottom: 1rem;
  }
  
  .margin-top--medium {
    margin-top: 1.5rem;
  }
  
  .margin-top--large {
    margin-top: 4rem;
  }
  
  .error {
    border: 1px solid red !important;
  }

  .slider-handle{
    background-color:yellowgreen;
  }


  .property-new-container {
    box-sizing: border-box;
    height: fit-content;
    background-color: transparent;
  }
  .property-new-container .payments .top-segment .deadline-text {
    color: #E4585A;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .property-new-container .payments .top-segment .payment-slider {
    border-radius: 4px;
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
  }
  .property-new-container .payments .top-segment .payment-slider .down-payment {
    margin-bottom: 12px;
  }
  .property-new-container .payments .top-segment .payment-slider .payment-text {
    color: #454A53;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .property-new-container .payments .top-segment .payment-slider .slider-wrapper {
    padding: 0;
    height: fit-content;
    border-radius: 13px;
    background-color: rgba(224, 225, 227, 0.2);
  }
  .property-new-container .payments .top-segment .payment-slider .slider-wrapper .date-slider {
    padding: 3px;
    height: 20px;
    border-radius: 13px;
    background-color: #E0E1E3;
    display: flex;
    align-items: center;
  }
  .property-new-container .payments .top-segment .payment-slider .slider-wrapper .date-slider .dot,
  .property-new-container .payments .top-segment .payment-slider .slider-wrapper .date-slider .lower {
    height: 14px;
    width: 14px;
    border-radius: 9.1px;
    background-color: #454A53;
  }
  .property-new-container .payments .top-segment .payment-slider .slider-wrapper .date-slider .due2 {
    background-color: #E4585A;
  }
  .property-new-container .payments .top-segment .payment-slider .slider-wrapper .date-slider .paid1 {
    background-color: black;
    margin-left: auto;
  }
  .property-new-container .payments .top-segment .payment-slider .slider-wrapper .date-slider .due1 {
    background-color: white;
  }
  .property-new-container .payments .top-segment .payment-slider .due-date {
    color: #E4585A;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
  .property-new-container .payments .top-segment .payment-slider .amount-paid {
    color: #A2A6AD;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
  }
  .property-new-container .payments .bottom-segment {
    letter-spacing: 0;
  }
  .property-new-container .payments .bottom-segment .summary-text {
    color: #454A53;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .property-new-container .payments .bottom-segment .summary-body {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
  }
  .property-new-container .payments .bottom-segment .summary-body .detailed-summary {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #EBEDEF;
  }
  .property-new-container .payments .bottom-segment .summary-body .category {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .property-new-container .payments .bottom-segment .summary-body .category .category-title {
    color: #878C94;
    font-size: 14px;
    line-height: 20px;
  }
  .property-new-container .payments .bottom-segment .summary-body .category .category-title-bold {
    font-weight: bold;
    color: #878C94;
  }
  .property-new-container .payments .bottom-segment .summary-body .category .category-amount {
    color: #4A4A4A;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    align-self: flex-end;
  }
  .property-new-container .payments .bottom-segment .summary-body .category .category-amount-bold {
    font-weight: bold;
    line-height: 24px;
    font-size: 20px;
  }
  .property-new-container .payments .bottom-segment .summary-body .total-amount {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #EBEDEF;
  }
  .property-new-container .payments .bottom-segment .summary-body .payable-amount {
    padding: 20px;
    border-radius: 4px;
    background-color: #F7F7F8;
  }
  .property-new-container .payments .bottom-segment .summary-body .pay-div {
    margin-top: 20px;
  }
  .property-new-container .payments .bottom-segment .summary-body .pay-div-button {
    padding: 0 60px;
  }
  
  #rangeValue {
    position: relative;
    display: block;
    text-align: center;
    font-size: 2em;
    color: #999;
    font-weight: 400;
  }
  .range {
    width: 375px;
  height: 18px;
  -webkit-appearance: none;
  background: #e0e1e3;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 1); */
  margin: 10px;
  }
  .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #00fd0a;
    cursor: pointer;
    border: 4px solid #333;
    box-shadow: -407px 0 0 400px #00fd0a;
  }
  td{
    text-align:center 
  }
  th{
    text-align:center 
  }
  
  
  
  
  
  
  
  
   
  