

.bg-gradient-primary-color {
    background: rgb(0,71,93);
background: linear-gradient(180deg, rgba(0,71,93,1) 0%, rgba(0,166,217,1) 100%);
   
}

.card-1{background: #2ECC71;}

.card-2{background: #E67E22;}

.card-3{background: #3498DB;}

.card-4{background: #182C61;}

.card {border: 0px !important}

.text-white{color: #fff;}

.icon-bg {
    background: #fff;
padding: 14px !important;
border-radius: 5px;
width: 60px;
height: 60px;
text-align: center;
}


.icon-bg i {
    color: #a4a4a4;
}


.sidebar .nav-item .nav-link span {
    font-size: .85rem;
    display: inline;
    text-transform: uppercase;
}

.sidebar-dark .nav-item .nav-link {
    color: rgba(255, 255, 255);
}

.sidebar-dark .nav-item .nav-link i {
    color: rgba(255, 255, 255);
    margin-right: 5px;
}


